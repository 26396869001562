import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import Col from "@prism/col";
import Row from "@prism/row";
import DeleteSuggestedDamageButton from "./DeleteSuggestedDamageButton";
import AddSuggestedDamageButton from "./AddSuggestedDamageButton";
import {deleteSuggestedDamage} from "../../../actions/SuggestedDamageActions";
import {clearSelectedFlatCarPanel} from "../../../actions/flatCarActions";
import FlatCarUtil from "./FlatCar/FlatCarUtil";
import {DamageDropdown, SeverityDropdown} from "./artComponents";
import Dropdown from "../../common/Dropdown";
import isEqual from "lodash.isequal";
import SuggestedDamageImageModal from "./SuggestedDamageImageModal";
import Message from "@prism/message";
import * as pricingUtils from "./PricingUtils";

const SuggestedDamageLineItem = (props) => {
    const {
        damage,
        deleteSuggestedDamage,
        clearSelectedFlatCarPanel,
        itemList,
        setApiLoading
    } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const {panelSuggestedDamages} = FlatCarUtil();

    const [suggestedDamage, setSuggestedDamage] = useState(damage);
    const [isDuplicate, setIsDuplicate] = useState(false);

    const handleDeleteSuggestedDamage = (suggestedDamage) => {
        deleteSuggestedDamage(suggestedDamage);
        if (!panelSuggestedDamages()) clearSelectedFlatCarPanel();
    }

    const handleSelectedItemChange = ({target: {selectedIndex, value}}) => {
        setSuggestedDamage(prevSuggestedDamage => ({
            ...prevSuggestedDamage,
            artCodes: {
                ...prevSuggestedDamage.artCodes,
                item: value,
                itemCode: itemList.items[selectedIndex].itemCode,
                subItemCode: itemList.items[selectedIndex].subItemCode,
                damage: '',
                damageCode: '',
                severity: '',
                severityCode: '',
            }
        }));
        updateDamageKey();
    }

    const isDuplicateDamage = useCallback(() => {
        if (!props.condition.damages) {
            return false;
        } else {
            return !!props.condition.damages.find(damage => damage.damageKey === suggestedDamage.newDamageKey)
        }
    }, [props.condition.damages, suggestedDamage]);

    useEffect(() => {
        setIsDuplicate(isDuplicateDamage());
    },[suggestedDamage, isDuplicateDamage]);

    const updateDamageKey = ()=> {
        setSuggestedDamage(prevSuggestedDamage => ({
            ...prevSuggestedDamage,
            newDamageKey: calculateDamageKey(prevSuggestedDamage)
        }));
    }

    const calculateDamageKey = (damage) => {
        return `L1-${damage.artCodes.itemCode}-${damage.artCodes.subItemCode}-${damage.artCodes.damageCode}`;
    };

    const handleDamageChange = ({target: {options, selectedIndex, value}}) => {
        setSuggestedDamage(prevSuggestedDamage => ({
            ...prevSuggestedDamage,
            artCodes: {
                ...prevSuggestedDamage.artCodes,
                damage: options[selectedIndex].text,
                damageCode: value,
                severity: '',
                severityCode: ''
            }
        }));
        updateDamageKey();
    };

    const handleSeverityChange = ({target: {options, selectedIndex, value}}) => {
        setSuggestedDamage(prevSuggestedDamage => ({
            ...prevSuggestedDamage,
            artCodes: {
                ...prevSuggestedDamage.artCodes,
                severity: options[selectedIndex].text,
                severityCode: value
            }
        }));
        updateDamageKey();
    };

    const setStatusMessage = (message) => {
        console.log("ERR MSG", message);
    };

    const isEdited = () => {
        return !isEqual(damage, suggestedDamage);
    }

    const showImage = () => {
        setModalOpen(true);
    };

    const toggleModal = () => {
        setModalOpen((prevState) => !prevState);
    };

    return (
        <div className="section-container" id={"sd-" + suggestedDamage.artCodes.damageKey}
             damage-id={suggestedDamage.artCodes.damageId}>
            <SuggestedDamageImageModal
                showModal={modalOpen}
                toggle={toggleModal}
                imageTitle={suggestedDamage.artCodes.item}
                damageKey={suggestedDamage.artCodes.damageKey}
                imageUrl={suggestedDamage.path}
            />
                <Col xs={12}>
                    <Row className="mt-2">
                        <Col xs={12} className="px-0">
                            <Dropdown onChange={handleSelectedItemChange}
                                      value={suggestedDamage.artCodes.item}
                                      id={'item-dropdown-'+suggestedDamage.artCodes.damageKey}>
                                      {/* Add initial value to list if not already present */}
                                {(!itemList?.items?.find(option => option.description === suggestedDamage.artCodes.item)) && (
                                    <option
                                        value={suggestedDamage.artCodes.item}>
                                        {suggestedDamage.artCodes.item}
                                    </option>
                                )
                                }
                                {itemList?.items?.map(option => (
                                        <option key={option.code}
                                                value={option.description}>
                                            {option.description}
                                        </option>
                                ))}
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className="px-0 mt-2">
                        <Col xs={6} className="px-1">
                            <DamageDropdown
                                id={'damage-dropdown-'+suggestedDamage.artCodes.damageKey}
                                onChange={handleDamageChange}
                                value={suggestedDamage.artCodes.damageCode}
                                description={suggestedDamage.artCodes.damage}
                                item={{
                                    itemCode: suggestedDamage.artCodes.itemCode,
                                    subItemCode: suggestedDamage.artCodes.subItemCode,
                                }}
                                groupCode={props.account.groupCode}
                                categoryCode={props.consignment.categoryCode}
                                setStatusMessage={setStatusMessage}
                            />
                        </Col>
                        <Col xs={6} className="px-1">
                            <SeverityDropdown
                                id={'severity-dropdown-'+suggestedDamage.artCodes.damageKey}
                                onChange={handleSeverityChange}
                                value={suggestedDamage.artCodes.severityCode}
                                item={{
                                    itemCode: suggestedDamage.artCodes.itemCode,
                                    subItemCode: suggestedDamage.artCodes.subItemCode
                                }}
                                description={suggestedDamage.artCodes.severity}
                                damageCode={suggestedDamage.artCodes.damageCode}
                                groupCode={props.account.groupCode}
                                categoryCode={props.consignment.categoryCode}
                                setStatusMessage={setStatusMessage}
                                disabled={isDuplicate}
                            />
                        </Col>
                    </Row>
                    {isDuplicate &&
                        <Message className="mx-1" id="duplicate-suggested-damage-message" color="danger">
                            This damage combination already exists.
                        </Message>
                    }
                    <Row>
                        <Col className="suggested-damage-image-container">
                            <img className="suggested-damage-image"
                                 id={'suggested-damage-image-' + suggestedDamage.artCodes.damageKey}
                                 src={suggestedDamage.path} alt={"Vehicle Damage"}
                                 onClick={showImage}/>
                        </Col>
                    </Row>


                    <Row className="suggested-damage-buttons py-2">
                        <Col style={{textAlign: 'left'}}>
                            <DeleteSuggestedDamageButton
                                suggestedDamage={suggestedDamage}
                                onClick={handleDeleteSuggestedDamage}
                            />
                        </Col>
                        <Col style={{textAlign: 'right'}}>

                            <AddSuggestedDamageButton
                                suggestedDamage={suggestedDamage}
                                panelSuggestedDamages={panelSuggestedDamages}
                                isEdited={isEdited()}
                                setApiLoading={setApiLoading}
                                isCRSO={pricingUtils.isCRSO(props.condition)}
                            />
                        </Col>
                    </Row>
                </Col>
        </div>
    );
};

const matchDispatchToProps = {
    deleteSuggestedDamage,
    clearSelectedFlatCarPanel
};

function mapStateToProps({itemList, account, consignment, condition}) {
    return {itemList, account, consignment, condition};
}

export default connect(mapStateToProps, matchDispatchToProps)(SuggestedDamageLineItem);
