import React from 'react';
import Row from "@prism/row";
import Col from '@prism/col';
import OutlinePanel from '../components/common/OutlinePanel';
import NumberInput from '../components/common/NumberInput';
import DataPair from '../components/common/DataPair';


export function DamagePricingInputs(props) {

    return <OutlinePanel className="py-2 px-0 mt-2 mb-3 bg-cerulean-light">
        <Col className="px-0">
            <Row>
                <Col xs={12}>

                    <DataPair label="Repair">
                        <Row className="ml-0">
                            <Col className="input-group mr-1 p-0">
                                <NumberInput
                                    name='repairLaborHours'
                                    value={props.data.repairLaborHours}
                                    id={'add-repair-labor-hours'}
                                    className="form-control"
                                    onBlur={props.handleUpdatePricing}
                                    onChange={props.priceChange}
                                    validationErrorText='Invalid hours'
                                    validateValue={99.99}
                                    callbackFromParent={props.handleInputValidation}
                                    disabled={props.data.disabled || props.damageNotChargeable}
                                    icon='clock'
                                />
                            </Col>
                            <Col className="input-group mr-1 p-0">
                                <NumberInput
                                    name='repairLaborCost'
                                    value={props.data.repairLaborCost}
                                    id={'add-repair-labor-cost'}
                                    className="form-control"
                                    onChange={props.priceChange}
                                    onBlur={props.handleManualUpdatePricing}
                                    validationErrorText='Invalid Cost'
                                    validateValue={99999.99}
                                    callbackFromParent={props.handleInputValidation}
                                    disabled={props.data.disabled || props.damageNotChargeable}
                                    icon='dollar-o'
                                />
                            </Col>
                        </Row>
                    </DataPair>

                </Col>
                <Col xs={12}>
                    <DataPair label="Paint">
                        <Row className="ml-0">
                            <Col className="input-group mr-1 p-0">
                                <NumberInput
                                    name='paintLaborHours'
                                    value={props.data.paintLaborHours}
                                    id={'add-paint-labor-hours'}
                                    className="form-control"
                                    onBlur={props.handleUpdatePricing}
                                    onChange={props.priceChange}
                                    validationErrorText='Invalid hours'
                                    validateValue={99.99}
                                    callbackFromParent={props.handleInputValidation}
                                    disabled={props.data.disabled || props.damageNotChargeable}
                                    icon='clock'
                                />
                            </Col>
                            <Col className="input-group mr-1 p-0">
                                <NumberInput
                                    name='paintLaborCost'
                                    value={props.data.paintLaborCost}
                                    id={'add-paint-labor-cost'}
                                    className="form-control"
                                    onChange={props.priceChange}
                                    onBlur={props.handleManualUpdatePricing}
                                    validationErrorText='Invalid Cost'
                                    validateValue={99999.99}
                                    callbackFromParent={props.handleInputValidation}
                                    disabled={props.data.disabled || props.damageNotChargeable}
                                    icon='dollar-o'
                                />
                            </Col>
                        </Row>
                    </DataPair>
                </Col>
                <Col xs={12}>
                    <DataPair label="Part">
                        <Row className="ml-0">
                            <Col className="input-group mr-1 p-0">
                                <NumberInput
                                    name='partLaborHours'
                                    value={props.data.partLaborHours}
                                    id={'add-part-labor-hours'}
                                    className="form-control"
                                    onBlur={props.handleUpdatePricing}
                                    onChange={props.priceChange}
                                    validationErrorText='Invalid hours'
                                    validateValue={99.99}
                                    callbackFromParent={props.handleInputValidation}
                                    disabled={props.data.disabled || props.damageNotChargeable}
                                    icon='clock'
                                />
                            </Col>
                            <Col className="input-group mr-1 p-0">
                                <NumberInput
                                    name='partLaborCost'
                                    value={props.data.partLaborCost}
                                    id={'add-part-labor-cost'}
                                    className="form-control"
                                    onChange={props.priceChange}
                                    onBlur={props.handleManualUpdatePricing}
                                    validationErrorText='Invalid Cost'
                                    validateValue={99999.99}
                                    callbackFromParent={props.handleInputValidation}
                                    disabled={props.data.disabled || props.damageNotChargeable}
                                    icon='dollar-o'
                                />
                            </Col>
                        </Row>
                    </DataPair>
                </Col>
                <Col xs={12}>
                    <Row className="ml-0">
                        <Col className="input-group mr-1 p-0">
                            <DataPair label="Part Cost">
                                <div className="input-group">
                                    <NumberInput
                                        name='partCost'
                                        value={props.data.partCost}
                                        id={'add-part-cost'}
                                        className="form-control"
                                        onBlur={props.handleUpdatePricing}
                                        onChange={props.priceChange}
                                        validationErrorText='Invalid hours'
                                        validateValue={99999.99}
                                        callbackFromParent={props.handleInputValidation}
                                        disabled={props.data.disabled || props.damageNotChargeable}
                                        icon='dollar-o'
                                    />
                                </div>
                            </DataPair>
                        </Col>
                        <Col className="input-group mr-1 p-0">
                            <DataPair label="Part Retail">
                                <div className="input-group">
                                    <NumberInput
                                        name='finalPartCost'
                                        value={props.data.finalPartCost}
                                        id={'add-part-retail'}
                                        className="form-control"
                                        onChange={props.priceChange}
                                        onBlur={props.handleManualUpdatePricing}
                                        validationErrorText='Invalid Cost'
                                        validateValue={99999.99}
                                        callbackFromParent={props.handleInputValidation}
                                        disabled={props.data.disabled || props.damageNotChargeable}
                                        icon='dollar-o'
                                    />
                                </div>
                            </DataPair>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </OutlinePanel>;
}

